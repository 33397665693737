import { useEffect } from 'react';

const useAudioPreload = (audioUrls) => {
  useEffect(() => {
    audioUrls.forEach(url => {
      const audio = new Audio();
      audio.preload = 'auto';
      audio.src = url;
    });
  }, [audioUrls]);
};

export default useAudioPreload;