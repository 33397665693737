import { Slider } from "@/components/ui/slider";
import { Volume2 } from "lucide-react";

const VolumeControl = ({ soundVolume, setSoundVolume }) => {
  return (
    <div className="flex items-center space-x-2 mb-4 text-white-smoke">
      <Volume2 className="h-6 w-6 " />
      <Slider
        value={[soundVolume]}
        onValueChange={(value) => setSoundVolume(value[0])}
        max={100}
        step={1}
        className="flex-grow h-1 bg-gray-100"
      />
    </div>
  );
};

export default VolumeControl;
