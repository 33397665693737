import { Input } from "@/components/ui/input";

const ThemeInput = ({ currentTheme, setCurrentTheme }) => {
  return (
    <div className="mb-8">
      <Input
        type="text"
        placeholder="Enter your theme (Optional)"
        value={currentTheme}
        onChange={(e) => setCurrentTheme(e.target.value)}
        className="border-0 border-b text-xl placeholder:opacity-50"
      />
    </div>
  );
};

export default ThemeInput;
