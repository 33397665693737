// TimerControls.js
import React from "react";
import { Play, Pause, RotateCcw, ChevronUp, ChevronDown } from "lucide-react";
import { Button } from "@/components/ui/button";

const TimerControls = ({
  timerActive,
  currentSet,
  sets,
  toggleTimer,
  resetTimer,
  incrementSets,
  decrementSets,
}) => (
  <div className="flex justify-between items-center mb-4">
    <div className="flex space-x-2">
      <Button
        onClick={toggleTimer}
        variant="ghost"
        className="p-2 hover:bg-light-blue"
      >
        {timerActive ? (
          <Pause className="h-6 w-6 fill-current" />
        ) : (
          <Play className="h-6 w-6 fill-current" />
        )}
      </Button>
      <Button
        onClick={resetTimer}
        variant="ghost"
        className="p-2 hover:bg-light-blue"
      >
        <RotateCcw className="h-6 w-6" />
      </Button>
    </div>
    <div className="flex items-center space-x-2">
      <span className="">
        Set: {currentSet} / {sets}
      </span>
      <div className="flex flex-col">
        <Button
          onClick={incrementSets}
          variant="ghost"
          className="p-1  hover:bg-light-blue"
        >
          <ChevronUp className="h-4 w-4" />
        </Button>
        <Button
          onClick={decrementSets}
          variant="ghost"
          className="p-1 hover:bg-light-blue"
          disabled={sets <= currentSet}
        >
          <ChevronDown className="h-4 w-4" />
        </Button>
      </div>
    </div>
  </div>
);

export default TimerControls;
