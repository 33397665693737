import React from 'react';
import TimerControls from "./TimerControls";
import TimerDisplay from "./TimerDisplay";
import TimerSettings from "./TimerSettings";
import BreakBanner from "./BreakBanner";
import useAudioPreload from './useAudioPreload';
import audioConfig from '../config/audioConfig';
import useTimer from "./useTimer";

const Timer = ({ currentTheme, setLogs, soundVolume, customAudioConfig }) => {
  const timerProps = useTimer(currentTheme, setLogs, soundVolume, customAudioConfig);
  useAudioPreload([
    audioConfig.workAudio,
    audioConfig.breakAudioDay,
    audioConfig.breakAudioNight
  ]);

  return (
    <div className={`transition-colors duration-300 ${timerProps.isBreak ? 'bg-slate-gray' : 'bg-raisin-black'}`}>
      <div className={`p-4 mb-4 ${timerProps.isBreak ? 'text-gray-800' : 'text-pink'}`}>
        <TimerSettings
          workTime={timerProps.workTime}
          breakTime={timerProps.breakTime}
          timerActive={timerProps.timerActive}
          setWorkTime={timerProps.setWorkTime}
          setBreakTime={timerProps.setBreakTime}
        />
        <TimerControls
          timerActive={timerProps.timerActive}
          currentSet={timerProps.currentSet}
          sets={timerProps.sets}
          toggleTimer={timerProps.toggleTimer}
          resetTimer={timerProps.resetTimer}
          incrementSets={timerProps.incrementSets}
          decrementSets={timerProps.decrementSets}
        />
        <TimerDisplay
          timeLeft={timerProps.timeLeft}
          isBreak={timerProps.isBreak}
          workTime={timerProps.workTime}
          breakTime={timerProps.breakTime}
        />
      </div>
      <BreakBanner 
        isBreak={timerProps.isBreak} 
        currentSet={timerProps.currentSet}
        totalSets={timerProps.sets}
      />
    </div>
  );
};

export default Timer;