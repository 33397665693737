const Log = ({ logs }) => {
  return (
    <div className="mt-8">
      <h2 className="mb-4 text-slate-gray">I have worked on... (logs)</h2>
      <ul className="text-slate-gray space-y-2 list-disc list-inside">
        {logs.map((log, index) => (
          <li
            key={index}
            className="flex justify-between items-center p-2"
          >
            <span className="">
              {log.start} - {log.end}
            </span>
            <span className="">
              {log.theme} ({log.duration} min)
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Log;
