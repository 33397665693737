// TimerSettings.js
import React from 'react';
import { Input } from '@/components/ui/input';

const TimerSettings = ({ workTime, breakTime, timerActive, setWorkTime, setBreakTime }) => (
  <div className="grid grid-cols-2 gap-4 my-4">
    <div>
      <label className="block mb-2">Work Time (min)</label>
      <Input
        type="number"
        value={workTime / 60}
        onChange={(e) => setWorkTime(Math.max(1, parseInt(e.target.value, 10)) * 60)}
        min="1"
        disabled={timerActive}
        className="border-0 border-b text-xl text-bold"
      />
    </div>
    <div>
      <label className="block mb-2 text-light-blue">Break Time (min)</label>
      <Input
        type="number"
        value={breakTime / 60}
        onChange={(e) => setBreakTime(Math.max(1, parseInt(e.target.value, 10)) * 60)}
        min="1"
        disabled={timerActive}
        className=" border-0 border-b text-xl text-bold border-light-blue text-light-blue"
      />
    </div>
  </div>
);

export default TimerSettings;