import React, { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Menu } from "lucide-react";
import Header from "./components/Header";
import InfoModal from "./components/InfoModal";
import Log from "./components/Log";
import Timer from "./components/Timer";
import VolumeControl from "./components/VolumeControl";
import ThemeInput from "./components/ThemeInput";
import SEO from './SEO';

const App = () => {
  const [currentTheme, setCurrentTheme] = useState("");
  const [logs, setLogs] = useState([]);
  const [soundVolume, setSoundVolume] = useState(50);
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  return (
    <div className="min-h-screen bg-raisin-black text-pink">
      <SEO 
        title="Pink Noise Pomodoro" 
        description="Pink noise is said to be effective in improving memory and work efficiency. This Pomodoro Timer allows you to set a timer at any desired work time and play pink noise during that time."
      />
      <div className="max-w-2xl mx-auto relative p-8 pb-24">
        <Header />
        <Button
          variant="ghost"
          className="absolute top-4 right-4 text-pink hover:bg-pink hover:bg-opacity-20"
          onClick={() => setIsInfoOpen(true)}
        >
          <Menu className="h-6 w-6" />
        </Button>
        <Timer
          currentTheme={currentTheme}
          setLogs={setLogs}
          soundVolume={soundVolume}
        />
        <VolumeControl
          soundVolume={soundVolume}
          setSoundVolume={setSoundVolume}
        />
        <div className="mt-8">
          <p className="mb-2">I am working on...</p>
          <ThemeInput
            currentTheme={currentTheme}
            setCurrentTheme={setCurrentTheme}
          />
        </div>
        <Log logs={logs} />
      </div>
      <InfoModal isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} />
    </div>
  );
};

export default App;