import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

const InfoModal = ({ isOpen, setIsOpen }) => {
  const feedbackUrl = "https://ofuse.me/dd9b5a76"; // フィードバックフォームのURLを設定してください

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="bg-raisin-black text-white">
        <DialogHeader>
          <DialogTitle>Information</DialogTitle>
        </DialogHeader>
        <div className="">
          <h3 className="font-bold">About</h3>
          <p>
            Pink noise is said to be effective in improving memory and work
            efficiency. This Pomodoro Timer allows you to set a timer at any
            desired work time and play pink noise during that time. I hope this
            is the best working timer for you.
          </p>
          <h3 className="font-bold mt-4">How to Use</h3>
          <ol className="list-decimal list-inside">
            <li>Set the work time and break time.</li>
            <li>Adjust the number of sets.</li>
            <li>
              Click the Play button to start the timer. Pink noise will be
              played during the work session.
            </li>
            <li>
              When it is time for a break, music will be played according to the
              time of day.
            </li>
            <li>
              When the work session ends or is interrupted, a work log is
              recorded.
            </li>
          </ol>
          <h3 className="font-bold mt-4">Music</h3>
          <p>
          Out-of-copyright musics from{" "}
            <a
              href="https://classix.sitefactory.info/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pink hover:opacity-90"
            >
              classix
            </a>
            <ul className="list-disc list-inside">
              <li>Bach - Orchestral Suite No. 3</li>
              <li>Ravel - Pavane for the Late Princess</li>
            </ul>
          </p>
          <div className="mt-6 flex justify-center items-center">
            <div className="">If you like it...</div>
            <a
              href="https://ko-fi.com/W7W6101ZV5"
              target="_blank"
              className="hover:bg-pink rounded-full p-1"
            >
              <img
                className="h-12"
                src="https://storage.ko-fi.com/cdn/kofi5.png?v=3"
                border="0"
                alt="Buy Me a Coffee at ko-fi.com"
              />
            </a>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InfoModal;
