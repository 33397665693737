// TimerDisplay.js
import React from 'react';

const TimerDisplay = ({ timeLeft, isBreak, workTime, breakTime }) => (
  <div className="mb-4 h-4  rounded-full overflow-hidden bg-gray-600">
    <div
      className="h-full rounded-full transition-all duration-1000 ease-linear"
      style={{
        width: `${(1 - timeLeft / (isBreak ? breakTime : workTime)) * 100}%`,
        background: isBreak ? '#ADD8E6' : '#FFC0CB',
      }}
    />
  </div>
);

export default TimerDisplay;