import { useCallback, useEffect, useRef, useState } from "react";
import audioConfig from "../config/audioConfig";

const useTimer = (
  currentTheme,
  setLogs,
  soundVolume,
  customAudioConfig = {}
) => {
  const [timerActive, setTimerActive] = useState(false);
  const [workTime, setWorkTime] = useState(25 * 60);
  const [breakTime, setBreakTime] = useState(5 * 60);
  const [timeLeft, setTimeLeft] = useState(workTime);
  const [isBreak, setIsBreak] = useState(false);
  const [sets, setSets] = useState(4);
  const [currentSet, setCurrentSet] = useState(1);
  const [startTime, setStartTime] = useState(null);

  const mergedAudioConfig = { ...audioConfig, ...customAudioConfig };

  const workAudioRef = useRef(null);
  const breakAudioRef = useRef(null);

  useEffect(() => {
    workAudioRef.current = new Audio(audioConfig.workAudio);
    breakAudioRef.current = new Audio(
      new Date().getHours() >= 6 && new Date().getHours() < 18
        ? audioConfig.breakAudioDay
        : audioConfig.breakAudioNight
    );

    workAudioRef.current.loop = true;
    breakAudioRef.current.loop = true;

    return () => {
      workAudioRef.current.pause();
      breakAudioRef.current.pause();
    };
  }, []);

  useEffect(() => {
    const volume = Math.min(Math.max(soundVolume / 100, 0), 1);
    if (Number.isFinite(volume)) {
      workAudioRef.current.volume = volume;
      breakAudioRef.current.volume = volume;
    }
  }, [soundVolume]);

  // 音声再生の制御
  useEffect(() => {
    if (timerActive) {
      if (isBreak) {
        workAudioRef.current.pause();
        breakAudioRef.current.play().catch(console.error);
      } else {
        breakAudioRef.current.pause();
        workAudioRef.current.play().catch(console.error);
      }
    } else {
      workAudioRef.current.pause();
      breakAudioRef.current.pause();
    }
  }, [timerActive, isBreak]);

  const fadeOut = (audio, duration = 500) => {
    return new Promise((resolve) => {
      const startVolume = audio.volume;
      const steps = 20;
      const volumeStep = startVolume / steps;
      const stepDuration = duration / steps;

      const fadeInterval = setInterval(() => {
        if (audio.volume > volumeStep) {
          const newVolume = Math.max(audio.volume - volumeStep, 0);
          audio.volume = Number.isFinite(newVolume) ? newVolume : 0;
        } else {
          audio.pause();
          audio.currentTime = 0;
          audio.volume = startVolume;
          clearInterval(fadeInterval);
          resolve();
        }
      }, stepDuration);
    });
  };

  const incrementSets = () => {
    setSets((prevSets) => prevSets + 1);
  };

  const decrementSets = () => {
    setSets((prevSets) => Math.max(currentSet, prevSets - 1));
  };

  const setWorkTimeWrapper = (newWorkTime) => {
    setWorkTime(newWorkTime);
    if (!timerActive && !isBreak) {
      setTimeLeft(newWorkTime);
    }
  };

  const resetTimer = async () => {
    setTimerActive(false);
    setTimeLeft(workTime);
    setIsBreak(false);
    setCurrentSet(1);
    await Promise.all([
      fadeOut(workAudioRef.current),
      fadeOut(breakAudioRef.current),
    ]);
    if (startTime) {
      logSession();
    }
    setStartTime(null);
  };

  const logSession = useCallback(() => {
    if (startTime) {
      const now = new Date();
      const duration = Math.round((now - startTime) / 1000 / 60);
      setLogs((prevLogs) => [
        ...prevLogs,
        {
          start: startTime.toLocaleTimeString(),
          end: now.toLocaleTimeString(),
          theme: currentTheme || "",
          duration: duration,
        },
      ]);
      setStartTime(null);
    }
  }, [startTime, currentTheme, setLogs]);
  const toggleTimer = () => {
    if (!timerActive) {
      setStartTime(new Date());
      setTimerActive(true);
      if (!isBreak) {
        // タイマーが停止状態から開始される場合、timeLeftをworkTimeに設定
        if (timeLeft === 0 || timeLeft === breakTime) {
          setTimeLeft(workTime);
        }
        workAudioRef.current.currentTime = 0;
        workAudioRef.current
          .play()
          .catch((error) => console.error("Error playing work audio:", error));
      } else {
        // 休憩時間の場合も同様に処理
        if (timeLeft === 0 || timeLeft === workTime) {
          setTimeLeft(breakTime);
        }
        breakAudioRef.current.currentTime = 0;
        breakAudioRef.current
          .play()
          .catch((error) => console.error("Error playing break audio:", error));
      }
    } else {
      setTimerActive(false);
      logSession();
      if (!isBreak) {
        fadeOut(workAudioRef.current);
      } else {
        fadeOut(breakAudioRef.current);
      }
    }
  };
  const handleTimerEnd = useCallback(async () => {
    if (!isBreak) {
      await fadeOut(workAudioRef.current);
      setIsBreak(true);
      setTimeLeft(breakTime);
      breakAudioRef.current.currentTime = 0;
      breakAudioRef.current
        .play()
        .catch((error) => console.error("Error playing break audio:", error));
      logSession();
    } else {
      await fadeOut(breakAudioRef.current);
      if (currentSet < sets) {
        setCurrentSet((prevSet) => prevSet + 1);
        setIsBreak(false);
        setTimeLeft(workTime);
        workAudioRef.current.currentTime = 0;
        workAudioRef.current
          .play()
          .catch((error) => console.error("Error playing work audio:", error));
      } else {
        setTimerActive(false);
        logSession();
      }
    }
  }, [isBreak, breakTime, currentSet, sets, workTime, logSession]);

  // タイマー更新のロジック
  useEffect(() => {
    let interval;
    if (timerActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      handleTimerEnd();
    }
    return () => clearInterval(interval);
  }, [timerActive, timeLeft, handleTimerEnd]);

  return {
    timerActive,
    workTime,
    breakTime,
    timeLeft,
    isBreak,
    sets,
    currentSet,
    toggleTimer,
    resetTimer,
    setWorkTime: setWorkTimeWrapper,
    setBreakTime,
    setSets,
    incrementSets,
    decrementSets,
  };
};

export default useTimer;
