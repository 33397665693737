import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const BreakBanner = ({ isBreak, currentSet, totalSets }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isBreak && currentSet <= totalSets) {
      const timer = setTimeout(() => setShow(true), 500); // 少し遅延させて表示
      return () => clearTimeout(timer);
    } else {
      setShow(false);
    }
  }, [isBreak, currentSet, totalSets]);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          exit={{ y: 100 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="fixed bottom-0 left-0 right-0 bg-light-blue text-raisin-black font-lg font-bold py-4 px-6 text-center shadow-lg"
        >
          Break Time: Keep your eyes off the screen
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BreakBanner;